// import axios from 'axios';
// import {  toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


export const AURCARDAPIBASE = 'https://api.aurcard.com/api/v1';
export const AURCARDBASE = 'https://sandbox.aurcard.com';

// export const isBase64 = (str) => {
//     const base64Pattern = /^([A-Za-z0-9+\/=]{4})*(?:[A-Za-z0-9+\/=]{2}==|[A-Za-z0-9+\/=]{3}=)?$/;
//     return base64Pattern.test(str);
// };

// export const getBase64OfImage = async (url = "") => {
//     const authToken = localStorage.getItem('auth_token');
//     if (!authToken) {
//         toast.error("Authentication token is missing.");
//         return null;
//     }

//     try {
//         const response = await axios.get(`${AURCARDAPIBASE}/url-to-base64?path=${url}`, {
//             headers: {
//                 Authorization: `Bearer ${authToken}`,
//             },
//         });

//         return response.data.base64;

//     } catch (error) {
//         toast.error(`Error fetching profile data: ${error.response?.data?.message || error.message}`);
//         return null;
//     }
// };;