import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import placeholder_image from "../../../images/placeholder_image.png";
import axios from 'axios';
import "../../../css/ContactWayForm.css"
import { AURCARDAPIBASE } from '../../../Utility/config';
import "../../../css/CreateCardStepper.css";

const ContactWayForm = ({ onSave, cardData,handleBack }) => {
  const [contactWays, setContactWays] = useState(cardData?.contactway || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [allContactOptions, setAllContactOptions] = useState([]); // State for all contact options

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      // Fetch contact options from the API
      axios.get(`${AURCARDAPIBASE}/fields`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then((response) => {
          if (response.data.success) {
            setAllContactOptions(response.data.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching contact options:', error);
        });
    }
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleAddContact = (newContact) => {
    setContactWays((prevContactWays) => {
      return [
        ...prevContactWays,
        {
          id: newContact.id,
          name: newContact.name,
          icon: newContact.icon,
          type: newContact.type,
          placeholder: newContact.placeholder,
          href_prefix: newContact.href_prefix,
          link: '',
          title: '',
        },
      ];

    });
    handleCloseDialog();
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    setContactWays((prevContactWays) => {
      const updatedContactWays = [...prevContactWays];
      updatedContactWays[index][name] = value;
      return updatedContactWays;
    });
  };


  const handleDeleteRow = (index) => {
    const updatedContactWays = contactWays.filter((_, i) => i !== index);
    setContactWays(updatedContactWays);
  };

  const handleSubmit = () => {
    onSave(contactWays);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, flexWrap: 'wrap' }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '48%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 2,
          }}
        >
          <Button sx={{ width: '20%' }} variant="contained" color="primary" onClick={handleOpenDialog}>
            +
          </Button>

          <Box
            sx={{
              maxHeight: 400,
              overflowY: 'auto',
              marginTop: 2,
            }}
          >
            {contactWays.map((contactWay, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2, justifyContent: "space-between", flexWrap: 'wrap' }}>
                <TextField
                  label={contactWay?.name}
                  name="link"
                  value={contactWay.link}
                  onChange={(e) => handleInputChange(index, e)}
                  sx={{ width: { xs: '100%', sm: '40%' } }} // Responsive width for mobile
                  margin="normal"
                />
                <TextField
                  label="Title (optional)"
                  name="title"
                  value={contactWay.title}
                  onChange={(e) => handleInputChange(index, e)}
                  fullWidth
                  sx={{ marginLeft: { xs: 0, sm: 2 }, width: { xs: '100%', sm: '30%' } }} // Responsive width for mobile
                  margin="normal"
                />
                <Button className='deleteBtn' color="error" sx={{ marginLeft: 1 }} onClick={() => handleDeleteRow(index)}>
                  <DeleteIcon sx={{ marginRight: 1 }} />
                </Button>
              </Box>
            ))}
          </Box>


        </Box>

        <Box
          sx={{
            width: { xs: '100%', sm: '48%' },
            padding: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxShadow: 3,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: { xs: 150, sm: 200 },
              backgroundImage: `url(${cardData?.basicinfo?.cover || placeholder_image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
              position: 'relative',
            }}
          >
            <Avatar
              src={cardData?.basicinfo?.avatar || placeholder_image}
              alt="Profile Picture"
              sx={{
                position: 'absolute',
                left: 20,
                bottom: -40,
                width: 100,
                height: 100,
                border: '4px solid white',
                '@media (max-width:600px)': {
                  left: 10,
                  bottom: -30,
                  width: 80,
                  height: 80,
                },
              }}
            />
          </Box>

          <Typography
            variant="h5"
            sx={{
              marginTop: 5,
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {cardData?.basicinfo?.name || ""}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              marginBottom: 2,
              color: 'text.secondary',
            }}
          >
            {cardData?.basicinfo?.job_title || ""}
          </Typography>

          <DialogContent sx={{ padding: 2 }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                },
                gap: { xs: 1, sm: 3 },
                justifyContent: contactWays?.length < 3 ? 'center' : 'start',
                maxWidth: { xs: 200, sm: 500, md: 700 },
                margin: '0 auto',
              }}
            >
              {contactWays?.map((contact) => (
                <Box
                  key={contact.name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: { xs: 0.5, sm: 1 },
                    borderRadius: 2,
                    boxShadow: 2,
                    backgroundColor: '#f0f0f0',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <IconButton>
                    <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                  </IconButton>
                  <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                    {contact.title || contact.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </DialogContent>
        </Box>

        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Select Contact Option</DialogTitle>
          <DialogContent sx={{ padding: 2 }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))', gap: 2 }}>
              {allContactOptions.map((option) => (
                <Box
                  key={option.name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    backgroundColor: '#f0f0f0',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleAddContact(option)}
                >
                  <IconButton>
                    <img src={option.icon} alt={option.name} style={{ width: 50, height: 50 }} />
                  </IconButton>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {option.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>

        {/* <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
        Save and Continue
      </Button> */}

      </Box>

      <div className='step-button-container'>
      <Button
          onClick={() => handleBack()}
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          BACK
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "center" }}
        >
          NEXT
        </Button>
      </div>
    </>

  );
};

export default ContactWayForm;
