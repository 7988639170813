import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarComponent from './Common/NavBarComponent';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './Screens/Login';
import Signup from './Screens/Signup';
import Home from './Screens/Home';
import PlansScreen from './Screens/PlansScreen';
import Profile from './Screens/Profile';
import CardScreen from './Screens/CardScreen';

const App = () => {
  return (
    <Router>
      <div className="App">
        <NavWrapper />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<Home />} />
          <Route path="/plans" element={<PlansScreen />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/qr" element={<CardScreen />} />
          <Route path="/qr/:slug" element={<CardScreen />} />
        </Routes>

      </div>
    </Router>
  );
};

const NavWrapper = () => {
  const location = useLocation();

  if (location.pathname === '/login' || location.pathname === '/signup' || location.pathname.startsWith('/qr')) {
    return null;
  }

  return <NavBarComponent />;
};

export default App;

