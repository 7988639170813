import React, { useRef, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography, Button, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCodeStyling from 'qr-code-styling';
import { AURCARDBASE } from '../Utility/config';
import { Download } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CardQRCodeDialog = ({ open, onClose, cardData }) => {
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);
  const cardLink = cardData ? `${AURCARDBASE}/qr/${cardData?.basicinfo?.slug || ''}` : '';

  useEffect(() => {
    if (open) {
      if (!qrCodeInstance.current) {
        qrCodeInstance.current = new QRCodeStyling({
          width: cardData?.qrcodeconfig?.qr_size || 200,
          height: cardData?.qrcodeconfig?.qr_size || 200,
          data: cardLink,
          dotsOptions: {
            color: cardData?.qrcodeconfig?.qr_dots_color || '#000000',
            type: cardData?.qrcodeconfig?.qr_dot_style || 'dots',
          },
          cornersSquareOptions: {
            color: cardData?.qrcodeconfig?.qr_square_color || '#000000',
            type: cardData?.qrcodeconfig?.qr_square_style || 'square',
          },
          cornersDotOptions: {
            color: cardData?.qrcodeconfig?.qr_dot_square_style || 'square',
            type: cardData?.qrcodeconfig?.qr_dot_square_style || 'square',
          },
          image: cardData?.qrcodeconfig?.qr_logo || cardData?.basicinfo?.logo || null,
          imageOptions: cardData?.qrcodeconfig?.qr_logo || cardData?.basicinfo?.logo || null
            ? {
              src: cardData?.qrcodeconfig?.qr_logo || cardData?.basicinfo?.logo || null,
              width: (cardData?.qrcodeconfig?.qr_size || 200) / 5,
              height: (cardData?.qrcodeconfig?.qr_size || 200) / 5,
              x: (cardData?.qrcodeconfig?.qr_size || 200) / 2 - (cardData?.qrcodeconfig?.qr_size || 200) / 10,
              y: (cardData?.qrcodeconfig?.qr_size || 200) / 2 - (cardData?.qrcodeconfig?.qr_size || 200) / 10,
              crossOrigin: "anonymous"
            }
            : { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
        });
      } else {
        qrCodeInstance.current.update({ data: cardLink });
      }

      setTimeout(() => {
        if (qrCodeRef.current) {
          qrCodeRef.current.innerHTML = '';
          qrCodeInstance.current.append(qrCodeRef.current);
        }
      }, 50);
    } else {
      if (qrCodeRef.current) qrCodeRef.current.innerHTML = '';
      qrCodeInstance.current = null;
    }
  }, [open, cardData]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(cardLink);
    toast.success("Link copied");
  };

  const handleDownloadQr = () => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.download({ name: cardData?.basicinfo?.slug || 'qr', extension: 'png' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <ToastContainer />

      <DialogTitle>Card QR Code</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" ref={qrCodeRef} marginBottom={2}></Box>

        <Divider sx={{ marginY: 2 }} />

        {/* <Typography variant="body1" textAlign="center" gutterBottom>
          <strong>Card Link:</strong>
        </Typography> */}
        <Typography variant="body2" textAlign="center" color="textSecondary" marginBottom={2}>
          {cardLink}
        </Typography>

        <Box display="flex" flexDirection="row" justifyContent="space-evenly">
          <Button variant="contained" color="primary" startIcon={<ContentCopyIcon />} onClick={handleCopyLink}>
            Copy Link
          </Button>
          <Button variant="contained" color="primary" startIcon={<Download />} onClick={handleDownloadQr}>
            Download
          </Button>
        </Box>

      </DialogContent>
    </Dialog>
  );
};

export default CardQRCodeDialog;
