import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../css/PlanComponent.css'; // Create this CSS file for styling

const PlanComponent = ({ planImage, price, currency, period, features }) => {
  return (
    <div className="plan-card">
      {/* Image Section with Price Overlay */}
      <div className="plan-card-image" style={{ backgroundImage: `url(${planImage})` }}>
        <div className="plan-price-overlay">
          <Typography variant="h6" color="primary" className="plan-price">
            {price} {currency}/{period}
          </Typography>
        </div>
      </div>
      
      <div className="plan-features">
        {features.map((feature, index) => (
          <div className="feature-item" key={index}>
            <CheckCircleIcon color="success" fontSize="small" className="feature-icon" />
            <Typography variant="body2">{feature}</Typography>
          </div>
        ))}
      </div>
      
      <Button variant="contained" color="primary" fullWidth className="subscribe-button">
        Subscribe
      </Button>
    </div>
  );
};

PlanComponent.propTypes = {
  planImage: PropTypes.string.isRequired, 
  price: PropTypes.number.isRequired, 
  currency: PropTypes.string.isRequired, 
  period: PropTypes.string.isRequired, 
  features: PropTypes.arrayOf(PropTypes.string).isRequired, 
};

export default PlanComponent;
