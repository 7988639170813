import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Avatar,
  Typography,
  DialogContent,
  IconButton,
} from '@mui/material';
import placeholder_image from "../../../images/placeholder_image.png";
import "../../../css/CreateCardStepper.css";

const CardStyleForm = ({ onSave, cardData, handleBack }) => {
  const [formData, setFormData] = useState({
    avatar_shape: cardData?.cardstyle?.avatar_shape || 'circle',
    avatar_align: cardData?.cardstyle?.avatar_align || 'center',
    cover_border_color: cardData?.cardstyle?.cover_border_color || '#000000',
    avatar_border_color: cardData?.cardstyle?.avatar_border_color || '#000000',
    background_color: cardData?.cardstyle?.background_color || '#ffffff',
    text_color: cardData?.cardstyle?.text_color || '#000000',
  });

  const shapes = ['none', 'circle', 'square', 'rectangle'];
  const alignments = ['none', 'left', 'center', 'right'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
  };



  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, flexWrap: 'wrap' }}>
        <Box sx={{
          width: { xs: '100%', sm: '48%' }, // Adjust for mobile screens
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: 2,
        }}>
          <TextField
            select
            label="Profile Image Shape"
            name="avatar_shape"
            value={formData.avatar_shape}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {shapes.map((shape) => (
              <MenuItem key={shape} value={shape}>
                {shape.charAt(0).toUpperCase() + shape.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Profile Image Alignment"
            name="avatar_align"
            value={formData.avatar_align}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {alignments.map((align) => (
              <MenuItem key={align} value={align}>
                {align.charAt(0).toUpperCase() + align.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            type="color"
            label="Cover Border Color"
            name="cover_border_color"
            value={formData.cover_border_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            type="color"
            label="Profile Border Color"
            name="avatar_border_color"
            value={formData.avatar_border_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            type="color"
            label="Card Background Color"
            name="background_color"
            value={formData.background_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <TextField
            type="color"
            label="Text Color"
            name="text_color"
            value={formData.text_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />


        </Box>

        <Box
          sx={{
            backgroundColor: formData.background_color,
            width: { xs: '100%', sm: '48%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 150,
              backgroundImage: `url(${cardData?.basicinfo?.cover || placeholder_image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
              border: `5px solid ${formData.cover_border_color}`,
              marginBottom: 2,
              position: 'relative',
            }}
          >
            <Avatar
              src={cardData?.basicinfo?.avatar || placeholder_image}
              alt="Profile"
              sx={{
                width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                height: 100,
                border: `4px solid ${formData.avatar_border_color}`,
                borderRadius: formData.avatar_shape === 'circle' ? '50%' : formData.avatar_shape === 'square' ? '0%' : '0%',
                position: 'absolute',
                bottom: -40,
                // Align based on the selected value of formData.avatar_align
                left: formData.avatar_align === 'left' ? 20 : formData.avatar_align === 'right' ? 'auto' : '50%',
                right: formData.avatar_align === 'right' ? 20 : 'auto',
                transform: formData.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
              }}
            />
          </Box>

          <Typography variant="h5" sx={{ textAlign: formData.avatar_align, marginTop: 5, fontWeight: 'bold', color: formData.text_color }}>
            {cardData?.basicinfo?.name || ""}
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: formData.avatar_align, color: formData.text_color }}>
            {cardData?.basicinfo?.job_title || ""}
          </Typography>

          {cardData?.contactway?.length > 0 ? (
            <DialogContent sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                  },
                  gap: { xs: 1, sm: 3 },
                  justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                  maxWidth: { xs: 200, sm: 500, md: 700 },
                  margin: '0 auto',
                }}
              >
                {cardData?.contactway?.map((contact) => (
                  <Box
                    key={contact.name}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: { xs: 0.5, sm: 1 },
                      borderRadius: 2,
                      boxShadow: 2,
                      backgroundColor: '#f0f0f0',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <IconButton>
                      <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                    </IconButton>
                    <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                      {contact.title || contact.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </DialogContent>
          ) : true}
        </Box>

      </Box>
      <div className='step-button-container'>
        <Button
          onClick={() => handleBack()}
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          BACK
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "center" }}
        >
          NEXT
        </Button>
      </div>
    </>

  );
};

export default CardStyleForm;
