import React from 'react';
import PlanComponent from '../Components/PlanComponent';
import planImage from '../images/plan-image.jpg';
import '../css/PlansScreen.css';
import { Badge } from '@mui/material';

const PlansScreen = () => {
    const plans = [
        {
            planImage: planImage,
            price: 55,
            currency: 'USD',
            period: 'MONTH',
            features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4']
        },
        {
            planImage: planImage,
            price: 70,
            currency: 'USD',
            period: 'MONTH',
            features: ['Feature A', 'Feature B', 'Feature C', 'Feature D']
        },
        {
            planImage: planImage,
            price: 99,
            currency: 'USD',
            period: 'MONTH',
            features: ['Feature X', 'Feature Y', 'Feature Z', 'Feature W']
        }
    ];

    return (
        <div className="plans-screen">
            <h2>Plans <Badge sx={{ color: "#DD9A63" }} color='#DD9A63'>{plans.length}</Badge></h2>
            <div className="plans-container">
                {plans.map((plan, index) => (
                    <PlanComponent
                        key={index}
                        planImage={plan.planImage}
                        price={plan.price}
                        currency={plan.currency}
                        period={plan.period}
                        features={plan.features}
                    />
                ))}
            </div>
        </div>
    );
};

export default PlansScreen;
