import React, { useState } from 'react';
import { Button, Box, Stack, Avatar, Typography, TextField, Grid, Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import placeholder_image from "../../../images/placeholder_image.png";
import "../../../css/CreateCardStepper.css";
const BasicInfoForm = ({ onSave, cardData }) => {
  const [imageError, setImageError] = useState(null);
  const formik = useFormik({
    initialValues: {
      avatar: cardData?.basicinfo?.avatar || placeholder_image,
      cover: cardData?.basicinfo?.cover || placeholder_image,
      logo: cardData?.basicinfo?.logo || placeholder_image,
      name: cardData?.basicinfo?.name || '',
      prefix: cardData?.basicinfo?.prefix || '',
      suffix: cardData?.basicinfo?.suffix || '',
      preferred_name: cardData?.basicinfo?.preferred_name || '',
      company: cardData?.basicinfo?.company || '',
      department: cardData?.basicinfo?.department || '',
      job_title: cardData?.basicinfo?.job_title || '',
      bio: cardData?.basicinfo?.bio || '',
      slug: cardData?.basicinfo?.slug || '',
    },
    validationSchema: Yup.object({
      avatar: Yup.mixed().required('Profile Picture is required'),
      cover: Yup.mixed().required('Cover is required'),
      logo: Yup.mixed().required('Logo is required'),
      name: Yup.string().required('Name is required'),
      slug: Yup.string().required('Sulg is required'),
    }),
    onSubmit: (values) => {
      onSave(values);
    },
  });


  const handleImageChange = (event, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      // const maxSize = 2 * 1024 * 1024;
      // if (file.size > maxSize) {
      //   setImageError("Image size must be 2 MB or less.");
      //   return;
      // } else {
      //   setImageError(null); 
      // }

      const reader = new FileReader();
      reader.onload = () => {
        formik.setFieldValue(fieldName, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box sx={{ padding: 2 }} component="form" onSubmit={formik.handleSubmit}>
      {imageError && <Alert severity="error" sx={{ mb: 2 }}>{imageError}</Alert>}

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Profile Picture</Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="profilePictureInput"
            onChange={(e) => handleImageChange(e, 'avatar')}
          />
          <label htmlFor="profilePictureInput">
            <Avatar
              src={formik.values.avatar}
              alt="Profile Picture"
              sx={{
                width: { xs: 60, sm: 80 },
                height: { xs: 60, sm: 80 },
                cursor: 'pointer',
              }}
            />
          </label>
        </Box>

        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Cover</Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="coverInput"
            onChange={(e) => handleImageChange(e, 'cover')}
          />
          <label htmlFor="coverInput">
            <Box
              component="img"
              src={formik.values.cover}
              alt="Cover"
              sx={{
                width: { xs: 200, sm: 300 },
                height: 80,
                objectFit: 'cover',
                borderRadius: 1,
                backgroundColor: '#f0f0f0',
                cursor: 'pointer',
              }}
            />
          </label>
        </Box>

        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Logo</Typography>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="logoInput"
            onChange={(e) => handleImageChange(e, 'logo')}
          />
          <label htmlFor="logoInput">
            <Avatar
              src={formik.values.logo}
              alt="Logo"
              sx={{
                width: { xs: 60, sm: 80 },
                height: { xs: 60, sm: 80 },
                cursor: 'pointer',
              }}
            />
          </label>
        </Box>
      </Stack>

      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="slug"
            name="slug"
            value={formik.values.slug}
            onChange={formik.handleChange}
            error={formik.touched.slug && Boolean(formik.errors.slug)}
            helperText={formik.touched.slug && formik.errors.slug}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Prefix"
            name="prefix"
            value={formik.values.prefix}
            onChange={formik.handleChange}
            error={formik.touched.prefix && Boolean(formik.errors.prefix)}
            helperText={formik.touched.prefix && formik.errors.prefix}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Suffix"
            name="suffix"
            value={formik.values.suffix}
            onChange={formik.handleChange}
            error={formik.touched.suffix && Boolean(formik.errors.suffix)}
            helperText={formik.touched.suffix && formik.errors.suffix}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Preferred Name"
            name="preferred_name"
            value={formik.values.preferred_name}
            onChange={formik.handleChange}
            error={formik.touched.preferred_name && Boolean(formik.errors.preferred_name)}
            helperText={formik.touched.preferred_name && formik.errors.preferred_name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Company"
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Department"
            name="department"
            value={formik.values.department}
            onChange={formik.handleChange}
            error={formik.touched.department && Boolean(formik.errors.department)}
            helperText={formik.touched.department && formik.errors.department}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            name="job_title"
            value={formik.values.job_title}
            onChange={formik.handleChange}
            error={formik.touched.job_title && Boolean(formik.errors.job_title)}
            helperText={formik.touched.job_title && formik.errors.job_title}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Bio"
            name="bio"
            value={formik.values.bio}
            onChange={formik.handleChange}
            error={formik.touched.bio && Boolean(formik.errors.bio)}
            helperText={formik.touched.bio && formik.errors.bio}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <div className='step-button-container'>
        <Button
          disabled
          variant="contained"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          BACK
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          NEXT
        </Button>
      </div>

    </Box>
  );
};

export default BasicInfoForm;
